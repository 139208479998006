.progressbar {
  @include position(fixed, 0, 0, 100%, 10px);
  z-index: 50;
  .progressbar_highlight {
    background: linear-gradient(
      272deg,
      rgba(67, 206, 162, 0.7) 0%,
      rgba(24, 90, 157, 0.7)
    );
    height: 10px;
    width: 9%;
  }
}
