body ul {
  padding: 0 0 0 1rem;
  margin: 0;
  &.p-0 {
    padding: 0;
  }
  li {
    list-style: none;
    margin-bottom: 5px;
  }
}

ul {
  &.unstyled {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }
  &.styled {
    padding-left: 0;
    align-items: center;
    justify-content: center;
    align-content: center;
    li {
      position: relative;
      svg {
        margin-right: 12px;
        color: $green;
      }
    }
  }
}
