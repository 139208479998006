html {
  body {
    background: #ecf0f3;
    color: #777;
    overflow-x: hidden;
    font-size: 1.1rem;
  }
  font-size: 16px;
  overflow-x: hidden;
  @media (max-width: 1199.98px) {
    font-size: 18px;
  }
  @media (max-width: 991.98px) {
    font-size: 16px;
  }
  @media (max-width: 767.98px) {
    font-size: 14px;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
  body {
    h1,
    h2 {
      font-family: "MontserratBold", sans-serif;
    }
    h3,
    h4,
    h5,
    h6 {
      font-family: "Metrophobic", sans-serif;
    }
  }
}
.font {
  &__h1 {
    font-size: 2.2rem;
    font-weight: bold;
    @media (min-width: 1200px) {
      font-size: 4rem;
    }
  }
  &__h2 {
    font-size: 1.8rem;
    font-weight: bold;
    @media (min-width: 1200px) {
      font-size: 3rem;
    }
  }
  &__h3 {
    font-size: 1.6rem;
    font-weight: bold;
    @media (min-width: 1200px) {
      font-size: 2rem;
    }
  }
  &__h4 {
    font-size: 1.4rem;
    font-weight: bold;
    @media (min-width: 1200px) {
      font-size: 1.8rem;
    }
  }
  &__h5 {
    font-size: 1.2rem;
    font-weight: bold;
    @media (min-width: 1200px) {
      font-size: 1.6rem;
    }
  }
  &__h6 {
    font-size: 1rem;
    font-weight: bold;
    @media (min-width: 1200px) {
      font-size: 1.4rem;
    }
  }
  &__sm {
    font-size: smaller;
  }
}
