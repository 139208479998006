/*********************/
.card {
  border-radius: 20px;
  background: $white;
  padding: 20px;
  // background: linear-gradient(145deg, #e2e8ec, #ffffff);
  background: linear-gradient(165deg, #fff 50%, #edebeb 80%);
  border: 1px solid #edebeb;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  img {
    @media (max-width: 768px) {
      margin: 10px auto;
      text-align: center;
      display: block;
      width: 100%;
      max-width: 220px;
    }
  }
  &__content {
    @include transform(translateY(10px));
    &:hover {
      @include transform(translateY(0));
      @include transition(transform 0.3s ease-in);
    }
  }

  @media (max-width: 968px) {
    .flex-center {
      display: block;
    }
  }
  .button {
    display: table;
    margin: 10px auto;
  }
}

.card-m0 {
  @extend .card;
  margin: auto;
}

.card2 {
  // box-shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;

  border-radius: 10px;
  padding: 35px 50px 35px 50px;
  background: linear-gradient(165deg, #fff 50%, #edebeb 80%);

  position: relative;
  overflow: hidden;
  &__content {
    transition: transform 0.3s ease-in;
    @include transform(translateY(10px));
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    &__content {
      @include transform(translateY(0));
    }
  }
  &:before {
    content: "";
    position: absolute;
    transition: opacity 0.3s ease-in;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 0;
    left: 0;
    background: linear-gradient(165deg, #fff 0%, #fafafa 50%);

    opacity: 0;
    z-index: 0;
  }
  .button__flat {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    @media (max-width: 768px) {
      opacity: 1;
    }
  }
}

.card2:hover .card2__content {
  @include transform(translateY(0));
}
.card2:hover .button__flat {
  opacity: 1;
}
