.ribbon {
  &__container {
    position: relative;
    overflow: hidden;
    padding: 20px 40px;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    /* transform: skewX(-23deg); */
    /* inset: 0 auto auto 0; */
    background: linear-gradient(178deg, rgb(67, 206, 162) 0%, rgb(24, 90, 157));
    color: #fff;
    /* transform-origin: 100% 0; */
    transform: translate(-45%) rotate(-45deg);
    padding: 4px 0px;
    top: 15px;
    left: 3px;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
  }
}
