@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes blink {
  50% {
    color: transparent;
  }
  0% {
    color: #fff;
  }
}

@keyframes spinningFadeIn {
  0% {
    width: 0px;
    transform: rotate(0deg);
    left: 50px;
  }
  100% {
    width: 100px;
    transform: rotate(720deg);
    left: 15px;
  }
}
@keyframes MoveUpDown {
  0%,
  100% {
    top: -35px;
  }
  50% {
    top: -25px;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate__fadeInLeft {
  @include animation(fadeInLeft 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67));
}

@keyframes flip {
  0% {
    @include transform(scaleX(-1));
    opacity: 0;
  }
  // 50% {
  //   @include transform(scaleX(-1));
  // }
  100% {
    @include transform(scaleX(1));
    opacity: 1;
  }
}
