.animation_delay-100 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.animation_delay-200 {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

.animation_delay-300 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

.animation_delay-400 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.animation_delay-500 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

.animation_delay-600 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.animation_delay-700 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}
.animation_delay-800 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}
.animation_delay-900 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}
.animation_delay-1000 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

.animation_delay-1100 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
}

.animation_delay-1200 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

.animation_flip {
  @include animation(flip 0.5s ease forwards);
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.animate__bounceIn {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@keyframes colorswap {
  0% {
    color: #898989;
  }
  20% {
    color: #706f6f;
  }
  40% {
    color: #3f3f3f;
  }
  60% {
    color: #706f6f;
  }
  80% {
    color: #898989;
  }
  100% {
    color: #898989;
  }
}

@-moz-keyframes colorswap {
  0% {
    color: #898989;
    border-color: #898989;
  }
  20% {
    color: #706f6f;
    border-color: #706f6f;
  }
  40% {
    color: #3f3f3f;
    border-color: #3f3f3f;
  }
  60% {
    color: #706f6f;
    border-color: #706f6f;
  }
  80% {
    color: #898989;
    border-color: #898989;
  }
  100% {
    color: #898989;
    border-color: #898989;
  }
}
.animate__colorSwap {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: colorswap;
  animation-name: colorswap;
}

@keyframes gradientcolorswap {
  0% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 2%) 0%,
      lighten(rgb(24, 90, 157), 2%) 100%
    );
  }
  5% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 4%) 0%,
      lighten(rgb(24, 90, 157), 4%) 100%
    );
  }
  10% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 6%) 0%,
      lighten(rgb(24, 90, 157), 6%) 100%
    );
  }
  15% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 8%) 0%,
      lighten(rgb(24, 90, 157), 8%) 100%
    );
  }
  20% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 10%) 0%,
      lighten(rgb(24, 90, 157), 10%) 100%
    );
  }
  25% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 12%) 0%,
      lighten(rgb(24, 90, 157), 12%) 100%
    );
  }
  30% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 14%) 0%,
      lighten(rgb(24, 90, 157), 14%) 100%
    );
  }
  35% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 16%) 0%,
      lighten(rgb(24, 90, 157), 16%) 100%
    );
  }
  40% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 18%) 0%,
      lighten(rgb(24, 90, 157), 18%) 100%
    );
  }
  45% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 20%) 0%,
      lighten(rgb(24, 90, 157), 20%) 100%
    );
  }
  50% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 22%) 0%,
      lighten(rgb(24, 90, 157), 22%) 100%
    );
  }
  55% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 20%) 0%,
      lighten(rgb(24, 90, 157), 20%) 100%
    );
  }
  60% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 18%) 0%,
      lighten(rgb(24, 90, 157), 18%) 100%
    );
  }
  65% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 16%) 0%,
      lighten(rgb(24, 90, 157), 16%) 100%
    );
  }
  70% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 14%) 0%,
      lighten(rgb(24, 90, 157), 14%) 100%
    );
  }
  75% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 12%) 0%,
      lighten(rgb(24, 90, 157), 12%) 100%
    );
  }
  80% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 10%) 0%,
      lighten(rgb(24, 90, 157), 10%) 100%
    );
  }
  85% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 8%) 0%,
      lighten(rgb(24, 90, 157), 8%) 100%
    );
  }
  90% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 6%) 0%,
      lighten(rgb(24, 90, 157), 6%) 100%
    );
  }
  95% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 4%) 0%,
      lighten(rgb(24, 90, 157), 4%) 100%
    );
  }
  100% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 2%) 0%,
      lighten(rgb(24, 90, 157), 2%) 100%
    );
  }
}

@-moz-keyframes gradientcolorswap {
  0% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 2%) 0%,
      lighten(rgb(24, 90, 157), 2%) 100%
    );
  }
  5% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 4%) 0%,
      lighten(rgb(24, 90, 157), 4%) 100%
    );
  }
  10% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 6%) 0%,
      lighten(rgb(24, 90, 157), 6%) 100%
    );
  }
  15% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 8%) 0%,
      lighten(rgb(24, 90, 157), 8%) 100%
    );
  }
  20% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 10%) 0%,
      lighten(rgb(24, 90, 157), 10%) 100%
    );
  }
  25% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 12%) 0%,
      lighten(rgb(24, 90, 157), 12%) 100%
    );
  }
  30% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 14%) 0%,
      lighten(rgb(24, 90, 157), 14%) 100%
    );
  }
  35% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 16%) 0%,
      lighten(rgb(24, 90, 157), 16%) 100%
    );
  }
  40% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 18%) 0%,
      lighten(rgb(24, 90, 157), 18%) 100%
    );
  }
  45% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 20%) 0%,
      lighten(rgb(24, 90, 157), 20%) 100%
    );
  }
  50% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 22%) 0%,
      lighten(rgb(24, 90, 157), 22%) 100%
    );
  }
  55% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 20%) 0%,
      lighten(rgb(24, 90, 157), 20%) 100%
    );
  }
  60% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 18%) 0%,
      lighten(rgb(24, 90, 157), 18%) 100%
    );
  }
  65% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 16%) 0%,
      lighten(rgb(24, 90, 157), 16%) 100%
    );
  }
  70% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 14%) 0%,
      lighten(rgb(24, 90, 157), 14%) 100%
    );
  }
  75% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 12%) 0%,
      lighten(rgb(24, 90, 157), 12%) 100%
    );
  }
  80% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 10%) 0%,
      lighten(rgb(24, 90, 157), 10%) 100%
    );
  }
  85% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 8%) 0%,
      lighten(rgb(24, 90, 157), 8%) 100%
    );
  }
  90% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 6%) 0%,
      lighten(rgb(24, 90, 157), 6%) 100%
    );
  }
  95% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 4%) 0%,
      lighten(rgb(24, 90, 157), 4%) 100%
    );
  }
  100% {
    background: linear-gradient(
      30deg,
      lighten(rgb(67, 206, 162), 2%) 0%,
      lighten(rgb(24, 90, 157), 2%) 100%
    );
  }
}

.animate__gradientColorSwap {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;

  -webkit-animation: gradientcolorswap 4s infinite,
    fadeInUp 1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  animation: gradientcolorswap 4s ease-in-out infinite,
    fadeInUp 1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  // animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
