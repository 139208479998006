@font-face {
  font-family: "Metrophobic";
  src: url("../src/assets/fonts/metrophobic/Metrophobic-Regular.ttf");
  /* src: local(--bs-font-sans-serif),
    url("../src/assets/fonts/metrophobic/Metrophobic-Regular.ttf") format("ttf"); */
  font-display: swap;
}

@font-face {
  font-family: "MontserratBold";
  src: url("../src/assets/fonts/montserrat/Montserrat-Bold.ttf");
  /* src: local(--bs-font-sans-serif),
    url("../src/assets/fonts/montserrat/Montserrat-ExtraBold.ttf") format("ttf"); */
  font-display: swap;
}
* {
  box-sizing: border-box;
}

html {
  font-family: "Metrophobic", sans-serif;
}

body.open {
  padding-right: 5px;
  overflow: hidden;
}
body .text-bold {
  font-weight: bold;
}
.mr-3 {
  margin-right: 1.5rem;
}
.mr-15p {
  margin-right: 15px;
}
.z-2 {
  z-index: 2;
  position: relative;
}
.z-3 {
  z-index: 2;
  position: relative;
}
.flex-center {
  display: flex;
  align-items: center;
}

body.theme-dark .bg-1 {
  background: #26282c;
}
.d-block {
  display: block;
  width: 100%;
}

.bg-1 {
  background: #f8f8f8;
}
.color_white {
  color: #ffffff;
}
.shadow--thin {
  border: 0.0625rem solid #d1d9e6;
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff !important;
}
.shadow--inset {
  border: 0.0625rem solid #d1d9e6;
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
}

.rounded--circle {
  border-radius: 50% !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f8f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #219ebc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #023047;
}

.shadow-inset {
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
}
.shadow-soft {
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
}
