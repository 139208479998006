@mixin themable($theme-name, $background, $heading, $buttons, $otherText) {
  .#{$theme-name} {
    color: $otherText;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $heading;
    }
    p {
      color: $otherText;
    }
    a {
      color: $white;
    }
    .main {
      background: $background;
    }
    .navbar {
      background: lighten($background, 5%);
      a {
        color: #fff;
      }
      .navbar-toggler {
        background: $white;
      }
    }
    button.button,
    a.button {
      border: 1px solid #242424;
      box-shadow: 0px 20px 20px #1c1d21;
      background: linear-gradient(145deg, #1e2024, #23272b);
      color: $white;
      &:hover,
      &:active,
      &:focus-visible {
        background: linear-gradient(341deg, #43cea2 0%, #185a9d);
      }
    }

    .scroll-y {
      &::-webkit-scrollbar-thumb {
        background: $white;
      }
      &::-webkit-scrollbar-track {
        background: #121212;
      }
    }

    // .banner-title {
    //   @extend .animate__gradientColorSwap;
    // }
    .custom-control-label {
      color: #121212;
    }
    .modal__container {
      background: $dark;
    }
    .color {
      &__primary {
        color: $green;
      }
    }

    .card2 {
      background: linear-gradient(145deg, #1e2024, #23272b);
      &:before {
        background: linear-gradient(to right bottom, #212428, #16181c);
      }
    }

    .button {
      &.button__flat {
        border: none;
        background: none;
        color: #c4cfde;
      }
    }

    .shadow {
      &__thin {
        box-shadow: 0px 20px 20px #1c1d21;
      }
    }

    .timeline {
      &__gutter {
        border-color: #212428;
      }
      &__wrapper {
        &:before {
          background: #212428;
        }
        &:after {
          border-color: #5b5c5e;
        }
      }
    }
    .banner-select {
      option {
        background: #23272b;
      }
    }
  }
}

@include themable(theme-dark, $dark, #c4cfde, $gray, $gray);

//Background
//H1,h2,h3,h4,h5,h6,p
//a
//button
//gradient
