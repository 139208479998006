.section {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

section.before:before {
  content: "";
  display: block;
  z-index: -1;
  background: rgb(222 224 245 / 0%);
  @include position(absolute, 0, 0, 100%, 100%);
  @include backdropFilter(blur(10px));
}
section.before.active:before {
  z-index: 25;
  background: rgb(222 224 245 / 50%);
}
#WorksSection {
  background: linear-gradient(
      257deg,
      rgb(255 255 255/ 90%) 0%,
      rgb(255 255 255/ 50%)
    ),
    url("../../../assets/images/workbg_dark.jpg");
  @include backgroundProp(fixed, no-repeat, center center, cover);
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}
#WorksSection:after {
  content: "";
  @include position(absolute, 0, 0, 100%, 100%);
  @include backdropFilter(blur(20px));
}

#WorksSection .animated {
  transform: scale(1.02);
}

@media (max-width: 768px) {
  #WorksSection .company_info {
    display: none;
  }
}
#HireMeSection {
  background: $bg_gradient1, url("../../../assets/images/hirebg.jpeg");
  @include backgroundProp(fixed, no-repeat, center center, cover);
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}
#HireMeSection:after {
  content: "";
  @include position(absolute, 0, 0, 100%, 100%);
  @include backdropFilter(blur(10px));
}

.hireme {
  width: 80%;
  margin: 0 10%;
  border: 20px solid $white;
  border-image-slice: 1;
  z-index: 2;
  position: relative;
}

#SkillsSection {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
