pre {
  text-align: left;
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #666;
}
.token-line {
  line-height: 1.3rem;
  height: 1.3rem;
}

div.line {
  display: table-row;
}

span.LineNo {
  display: table-cell;
  text-align: right;
  padding-right: 1rem;
  user-select: none;
  opacity: 0.5;
}

span.LineContent {
  display: table-cell;
}
